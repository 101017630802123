<template>
	<div class="myCart">
		<div class="myCart_tit">购物车</div>
		<div class="myCartList" v-if="myCartList.length">
			<el-checkbox-group v-model="checkedCart" @change="handleCheckedCitiesChange">
			    <div class="CartItem" v-for="(item,index) in myCartList" :key="index">
					<el-checkbox :label="item.car_course_id"></el-checkbox>
					<div class="cou_thumb_box">
						<img :src="item.course.cou_thumb" v-if="item.course.cou_thumb" class="cou_thumb" />
						<img src="@/views/images/coursesImg.png" class="cou_thumb" v-else />
					</div>
					<div class="courseInfo">
						<p class="cou_name">{{item.course.cou_name}}</p>
						<p class="cou_TY"><span class="cou_type">{{item.course.cou_name}}</span><span class="cou_year">【{{item.course.cou_year}}年】</span></p>
					</div>
					<div class="cou_price">￥{{item.course.cou_price}}</div>
					<i class="el-icon-delete delete" @click="deleteCourse(item)"></i>
				</div>
			</el-checkbox-group>
			<div class="checkAll">
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
				<p class="total_box">合计：<span class="total_bs">￥</span><span class="total_num">{{total}}</span></p>
				<div class="gotoPay" :class="{active:checkedCart.length}" @click="gotoPay">去支付</div>
			</div>
		</div>
		<div class="noData" v-else>
			<img src="@/views/images/noData.png" />
			<div class="noDataTips">
				<span>您暂未选择课程，点击</span>
				<span class="cBlue"><router-link to='/CourseCenter'>课程列表</router-link></span>
				<span>选择课程</span>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';  
	export default {
	    data() {
			return {
				myCartList:[],//购物车列表
				checkedCart:[],//选中的课程
				isIndeterminate:false,
				checkAll:false,//是否全选
				total:0,//合计
			}
		},
		methods:{
			//获取购物车列表
			getCartList(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Carts/index.html", {
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.myCartList = response.data.data.data;
						Bus.$emit('busCartNum', response.data.data.total);//购物车数量
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击多选
			handleCheckedCitiesChange(value){
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.myCartList.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.myCartList.length;
				//计算价格
				this.calculatePrice();
			},
			//全选
			handleCheckAllChange(val) {
				var myCartId = [];
				for(var i = 0;i<this.myCartList.length;i++ ){
					myCartId.push(this.myCartList[i].car_course_id)
				}
				this.checkedCart = val ? myCartId : [];
				this.isIndeterminate = false;
				//计算价格
				this.calculatePrice();
			},
			//去支付
			gotoPay(){
				if(this.checkedCart.length){
					this.$router.push({
						path: "/confirmOrder",
						query: {
							course_id:this.PublicJs.Encrypt(this.checkedCart.join(",")),
							isCarts:this.PublicJs.Encrypt(1)
						}
					});
				}else{
					this.$message({
						message: "请先选择要购买的课程",
						type: 'warning',
						showClose: true
					});
				}
			},
			//计算价格
			calculatePrice(){
				if(this.checkedCart.length){
					this.total = 0
					for(var i=0;i<this.checkedCart.length;i++){
						for(var j=0;j<this.myCartList.length;j++){
							if(this.checkedCart[i] == this.myCartList[j].car_course_id){
								this.total = this.total + Number(this.myCartList[j].course.cou_price)
							}
						}
					}
				}else{
					this.total = 0
				}
			},
			//删除课程
			deleteCourse(item){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Carts/del.html", {
					token:window.sessionStorage.getItem('token'),
					ids:this.PublicJs.Encrypt(item.car_id)
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						//获取购物车列表
						this.getCartList();
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
		},
		created(){
			//获取购物车列表
			this.getCartList()
		}
	}
</script>

<style scoped="scoped">
	.myCart{
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 90px;
	}
	.myCart_tit{
		font-size: 22px;
		color: #333;
		line-height: 78px;
		font-weight: bold;
	}
	.CartItem{
		width: 100%;
		height: 114px;
		background-color: #FFF;
		margin-bottom: 16px;
		font-size: 0;
		position: relative;
	}
	.CartItem .el-checkbox{
		display: inline-block;
		width: 60px;
		height: 114px;
		text-align: center;
		line-height: 114px;
		vertical-align: top;
	}
	.cou_thumb_box{
		width: 120px;
		line-height: 114px;
		display: inline-block;
		margin-left: 44px;
		margin-right: 16px;
	}
	.cou_thumb{
		width: 120px;
		height: 72px;
		border-radius: 4px;
		vertical-align: middle;
	}
	.courseInfo{
		display: inline-block;
		width: 550px;
		height: 114px;
		vertical-align: top;
		padding-top: 12px;
	}
	.cou_name{
		font-size: 14px;
		color: #333;
		line-height: 50px;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
	}
	.cou_type{
		display: inline-block;
		height: 22px;
		padding: 0 5px;
		background-color: #ffac3d;
		color: #FFF;
		border-radius: 4px;
		font-size: 12px;
		line-height: 22px;
		margin-right: 8px;
	}
	.cou_year{
		font-size: 14px;
		color: #333;
	}
	.cou_price{
		display: inline-block;
		width: 150px;
		height: 114px;
		text-align: center;
		line-height: 114px;
		font-size: 14px;
		color: #333;
	}
	.delete{
		position: absolute;
		font-size: 20px;
		color: #A2A2A2;
		top: 47px;
		right: 82px;
		cursor: pointer;
	}
	.delete:hover{
		color: #FF4400;
	}
	.checkAll{
		width: 100%;
		height: 54px;
		background-color: #fff;
		position: relative;
	}
	.checkAll .el-checkbox{
		line-height: 54px;
		padding-left: 23px;
	}
	.total_box{
		width: 150px;
		position: absolute;
		top: 0;
		left: 790px;
		text-align: center;
		line-height: 54px;
		font-size: 15px;
		color: #999;
	}
	.total_box .total_bs{
		font-size: 14px;
		color: #f30c0c;
	}
	.total_box .total_num{
		font-size: 22px;
		color: #f30c0c;
	}
	.gotoPay{
		width: 100px;
		height: 36px;
		background-color: #BBBBBB;
		line-height: 36px;
		text-align: center;
		border-radius: 18px;
		position: absolute;
		top: 9px;
		right: 45px;
		color: #fff;
		font-size: 14px;
		cursor: pointer;
	}
	.gotoPay.active{
		background-color: #f30c0c;
	}
	.noData{
		text-align: center;
		width: 100%;
		margin-top: 53px;
	}
	.noDataTips{
		font-size: 16px;
		color: #333;
		margin-top: 25px;
	}
	.cBlue a{
		color: #4d6ef2;
	}
	.cBlue a:hover{
		color: #1d48d2;
	}
</style>
<style>
	.CartItem .el-checkbox__label{
		display: none;
	}
</style>
